import _ from 'lodash';
import { useSelector } from 'react-redux';
import { selectConfig } from 'redux/selectors';
import GunbotStorage from 'assets/js/gunbot.storage';

var isTrueSet = value => (String(value).toLowerCase() === 'true');

const useBitrageActivated = () => {
  const config = useSelector(selectConfig);
  const bitRaged = useSelector(state => !!state.settings.corememData.licRank || false);
  let localBitRage = false;
  const licenseDetails = GunbotStorage.get('licenseDetails', null);
  if (!_.isNil(licenseDetails)) {
    localBitRage = (licenseDetails.requiredTokenAmount * 100) / licenseDetails.tokenBalance <= 20 || isTrueSet(licenseDetails.mm) || isTrueSet(licenseDetails.defi) || licenseDetails.slots === 9999;
  }
  return (bitRaged || localBitRage) && config.bot.HG;
};

export default useBitrageActivated;
