import { useMemo } from 'react';

import GunbotStorage from 'assets/js/gunbot.storage';
import $scope from 'consts/scope';

const paramInfo = $scope.setupWizard.exchange.settings.parameters;
const futuresExchanges = $scope.marginExchanges;
const bothExchanges = $scope.bothExchanges;

export default function useAvailableExchanges(gunthy_wallet, marketType = 'both') {
  return useMemo(() => {
    if (!gunthy_wallet) return [];

    const { type, slots = 1, defi = false } = GunbotStorage.get('licenseDetails');

    let exchanges =
    (slots === 9999 || defi)
        ? [...paramInfo.exchangeName.values, ...paramInfo.exchangeNameMore.values, ...paramInfo.exchangeNameDefi.values]
        : [...paramInfo.exchangeName.values, ...paramInfo.exchangeNameMore.values];

    if (marketType !== 'both') {
      exchanges = exchanges.filter(exchange => isMarketTypeExchange(exchange.value, marketType));
    }

    return exchanges.sort((a, b) => a.value.localeCompare(b.value));
  }, [gunthy_wallet, marketType]);
}

export const isMarketTypeExchange = (exchange, marketType) => {
  if (bothExchanges.includes(exchange)) return true;
  const isFutures = futuresExchanges.includes(exchange);
  return (marketType === 'futures' && isFutures) || (marketType === 'spot' && !isFutures);
};

export const isEqualMarketType = (market = "", marketType = "") => {
  return market.includes(marketType) || (marketType === 'futures' && market === 'swap') || (marketType === 'futures' && market === 'delivery')
    || (marketType === 'futures' && market === 'unified_spot')
    || (marketType === 'futures' && market === 'unified_inverse')
    || (marketType === 'futures' && market === 'unified_linear')
    || (marketType === 'futures' && market === 'normal_spot')
    || (marketType === 'futures' && market === 'normal_linear');
};
