import _ from "lodash";

const useEmbededView = (history) => {
  const urlExchange = new URLSearchParams(history.location.search).get('exchange');
  const urlPair = new URLSearchParams(history.location.search).get('pair');

  if (!_.isNil(urlExchange)) {
    localStorage.setItem('appExchange', urlExchange);
  }
  if (!_.isNil(urlPair)) {
    localStorage.setItem('appPair', urlPair);
  }

  return !_.isNil(urlExchange) && !_.isNil(urlPair);
}

export default useEmbededView;