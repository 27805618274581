import { useEffect, useState } from "react";

const useMobileView = () => {
  const [isMobile, setMobile] = useState(window?.innerWidth < 960);

  useEffect(() => {
    const handleResize = () => setMobile(window?.innerWidth < 960);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

export default useMobileView;