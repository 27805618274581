import api from 'api';
import _ from 'lodash';

export const checkGunthyWallet = async wallet => {
  return api.checkGunthyWallet(wallet).then(result => {
    if (result.status === 'success') {
      localStorage.setItem('licenseDetails', JSON.stringify(result.details));
      localStorage.setItem('licenseDetailsLastUpdate', Math.round(+new Date() / 1000));
    }
  })
}

export const getLicenseDetails = () => {
  let licenseDetails;
  const detailsStr = localStorage.getItem('licenseDetails');
  const licenseDetailsLastUpdate = localStorage.getItem('licenseDetailsLastUpdate') || 0;
  if (!_.isNil(detailsStr)) {
    licenseDetails = JSON.parse(detailsStr);
  }

  return { licenseDetails, licenseDetailsLastUpdate }
}