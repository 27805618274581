import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { isMobile } from 'react-device-detect';

import { Injected } from 'providers/Web3ManageProvider';

const useEagerConnect = connected => {
  const { activate, active } = useWeb3React();
  const [checkedConnection, setCheckedConnection] = useState(false);

  useEffect(() => {
    if (connected) {
      Injected.isAuthorized().then(isAuthorized => {
        if (isAuthorized) {
          activate(Injected, undefined, true).catch(() => {
            setCheckedConnection(true);
          });
        } else {
          if (isMobile && window.ethereum) {
            activate(Injected, undefined, true).catch(() => {
              setCheckedConnection(true);
            });
          } else {
            setCheckedConnection(true);
          }
        }
      });
    } else {
      setCheckedConnection(true);
    }
  }, [activate, connected]);

  useEffect(() => {
    if (active) {
      setCheckedConnection(true);
    }
  }, [active]);

  return checkedConnection;
};

export default useEagerConnect;
