import { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';

import { Injected } from 'providers/Web3ManageProvider';

const useInactiveListener = checkedConnect => {
  const { active, error, activate } = useWeb3React();

  useEffect(() => {
    const { ethereum } = window;
    if (checkedConnect && ethereum && ethereum.on && !active && !error) {
      const handleChainChanged = chainId => {
        activate(Injected);
      };

      const handleAccountsChanged = accounts => {
        if (accounts.length > 0) {
          activate(Injected);
        }
      };

      const handleNetworkChanged = networkId => {
        activate(Injected);
      };

      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);
      ethereum.on('networkChanged', handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
          ethereum.removeListener('networkChanged', handleNetworkChanged);
        }
      };
    }
  }, [active, error, checkedConnect, activate]);
};

export default useInactiveListener;
