const { useEffect, useContext, useState } = require('react');
const { SocketContext } = require('SocketIO');

const useSocketConnect = () => {
  const socket = useContext(SocketContext);
  const [connect, setConnect] = useState(false);

  useEffect(() => {
    const connectHandler = () => setConnect(true);
    const disconnectHandler = () => setConnect(false);

    socket.on('connect', connectHandler);
    socket.on('disconnnect', disconnectHandler);

    return () => {
      socket.on('connect', connectHandler);
      socket.on('disconnnect', disconnectHandler);
    };
  }, [socket]);

  return connect;
};

export default useSocketConnect;
